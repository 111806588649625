import React from "react"
import Scrollspy from "react-scrollspy"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Footer from "../components/footer"
import Recommendations from "../components/recommendations"
import { Link } from "gatsby"
import Preview from '../components/Preview'

const Quizboot = props => (
    <Layout>

        <Preview />
        <Hero
            title="Quizboot"
            summary="Designing a consistent mobile and desktop web experience on a quiz platform 📚"
            cover="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1586254226/QuizbootHero.png"
        />

        <div class="grid md:grid-cols-3 px-6 md:px-24">
            <div class="text-left md:w-7/12 hidden md:block">

                <div
                    data-sal="slide-right"
                    data-sal-duration="900"
                    data-sal-delay="2000"
                    data-sal-easing="ease"
                    class="sticky top-32 flex flex-col gap-1.5 ml-2">



                    <p class="container font-semibold text-theme-text py-2 text-sm tracking-wider text-left">
                    OVERVIEW ⤵
                    </p>
                    <Scrollspy
                        items={[
                            "section-1",
                            "section-2",
                            "section-3",
                            "section-4",
                            "section-5",
                        ]}
                        class="container text-main-text ml-8 w-8/12 py-1.5 rounded-sm cursor-pointer"
                        currentClassName="text-main-text container -ml-4 my-1 px-4 w-auto py-1.5 rounded-sm border-orange-600 border-l-4 bg-secondary font-bold">
                        <li class="">
                            <a href="#section-1">About project</a>
                        </li>
                        <li class="">
                            <a href="#section-2">Design process</a>
                        </li>
                        <li class="">
                            <a href="#section-3">Visual design</a>
                        </li>
                        <li class="">
                            <a href="#section-4">Review and Handoff</a>
                        </li>
                        <li class="">
                            <a href="#section-5">Outcomes and Learnings</a>
                        </li>
                    </Scrollspy>

                    {/* <div class="bg-secondary bg-special-500 rounded-sm p-5 my-12">
                        <blockquote class="text-left w-auto"><p>Learn more about my skills and experience</p></blockquote>

                        <Link to="https://res.cloudinary.com/dcj89ranh/image/upload/v1641133338/TrustScore/Badara_Olawale_Resume.pdf" target="_blank" data-sal="slide-up"
                            data-sal-duration="700"
                            data-sal-delay="310"
                            data-sal-easing="ease" class="transform transition duration-500 ease-in-out bg-main-text py-3 px-5 mt-4 text-secondary shadow-lg rounded-full float-left font-semibold text-sm hover:shadow-2xl hover:opacity-90">View my Resume</Link>

</div> */}
                </div>
            </div>

            <div class="divide-y divide-divider md:col-span-2 grid grid-cols-1 gap-8 md:gap-12">
                <section id="section-1" class="current">
                    <p class="text-2xl font-semibold text-left pt-8 pb-4">About project</p>
                    <div class="subpixel-antialiased md:text-lg md:text-l font-base leading-7 md:leading-8 pb-8 text-left break-words">
                        <p>
                            <a href="https://quizboot.com" class="underline" target="_blank">Quizboot</a> is an online social learning platform with the goal of providing fun challenges and monetary rewards.
                            The platform existed already and had a sizeable number of users (500+) but despite this, only a few of them used
                            the platform frequently. I worked on redesigning the platform in 2019 and since it
                            was a redesign project, it was important to identify the challenge with
                            the current platform before exploring design solutions.
                        </p>
                        <p>
                            I worked as the lone designer on the project and managed the design process from end-to-end (research to visual design).
                        </p>
                    </div>


                    <div class="md:flex gap-8">
                        <div class="rounded-sm bg-secondary p-4">
                            <p class="text-sm md:text-md tracking-wider font-semibold pb-2 text-left">
                                ROLE IN PROJECT
                            </p>
                            <p class="text-base md:text-l font-base text-left leading-7">
                                UX Designer • Research, Information Architecture, Visual Design
                            </p>
                        </div>

                        <div class="rounded-sm bg-secondary p-4">
                            <p class="text-sm md:text-md tracking-wider font-semibold text-left pb-2 pt-8 md:pt-0">
                                PROJECT GOAL(s)
                            </p>

                            <p class="text-left">
                                Deliver a great, consistent desktop and mobile web experience.
                            </p>
                            <p class="text-left pt-2">
                                Ensure features are easy to discover by improving the
                                information architecture.
                            </p>

                        </div>
                    </div>

                </section>

                <section id="section-2" class="current">
                    <p class="text-2xl font-semibold text-left pb-4 pt-8">
                        Design process
                    </p>
                    <p class="subpixel-antialiased text-base md:text-l font-base leading-7 pb-4 text-left">
                        Here is a visual representation of the design process in this project. The discovery phase spans
                        across all phases as more insights are to be gotten even after the launch in order to iterate and improve.
                    </p>

                    <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610818834/quizboot/Design-process.svg"
                        class=""
                        alt="quizboot-process"
                    />

                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7   pb-4 text-left pt-8 break-words">
                        The first step was to identify the challenge. Consequently,
                        more time was spent on gathering requirements (from
                        stakeholders) and discovering how users interact with the current platform.
                    </p>

                    <p class="text-xl font-semibold text-left pt-8 pb-3">Discovering the challenge</p>
                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7   pb-4 text-left break-words">
                        In order to make the design process inclusive, a dropbox paper was created. The paper was open for
                        collaboration so the stakeholders could be included in design process allowing
                        them to share information, see the proposed timeline for different phases and
                        view the draft of survey questions.
                    </p>
                    <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610830100/quizboot/Paper_Documentation.svg"
                        class="mx-auto md:w-11/12"
                        alt="quizboot-discover"
                    />
                    <p class="subpixel-antialiased py-0 text-sm font-base leading-7   pb-4 text-center text-gray-300">
                        Dropbox paper documentation
                    </p>
                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7   pb-4 text-left break-words">
                        To discuss what was documented in the paper doc, comments as well as
                        calls were necessary to align. A specification document with
                        existing features along with data about existing users (active and
                        inactive) was made available and features were discussed extensively
                        in our initial remote meetings.
                    </p>


                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 pb-4 text-left break-words">
                        Heading to user research in the discovery phase, it was important to
                        clearly define the goal of the research which was summed up as - “to
                        understand what features are valuable to the active users as well as
                        any pain points they encounter with using the features” and for the
                        inactive users - “to understand why the stopped using the platform,
                        what would make them get back to using it” and for both category of
                        users to know - ”what features they would find valuable if included
                        on the platform”.
                    </p>

                    <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610830891/quizboot/Survey_Analysis.svg"
                        class="md:w-8/12 mx-auto"
                        alt="quizboot-discover"
                    />
                    <p class="subpixel-antialiased py-0 text-sm font-base leading-7 pb-4 text-center text-gray-300">
                        Analysing survey responses from users
                    </p>
                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 pb-4 text-left break-words">
                        From the survey the major challenge was identified as users not knowing what value they could gain from the platform
                        and also helped identify what apps users interact with
                        frequently including what platform they access quizboot on among
                        other valuable insights.
                    </p>

                    <p class="text-xl font-semibold text-left pt-8 pb-4">Understand</p>
                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                        In order to understand the challenge, I gathered findings from the research and
                        the heuristics evaluation of the existing platform, analyzing both.
                    </p>

                    <p class="subpixel-antialiased text-base md:text-l font-base leading-7   pb-4 text-left break-words">
                        This insights helped me to identify typical traits of users
                        and ultimately align with the goal of
                        the research.
                    </p>
                    <div class="md:grid grid-cols-2">
                        <div class="">
                            <img
                                src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610831712/quizboot/Mobile_users.svg"
                                class="w-9/12 mx-auto"
                                alt="quizboot-discover" />
                        </div>

                        <div class="">

                            <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 pb-4 text-left break-words">
                                Based on responses from the survey the following findings were
                                deduced:

                                <ul class="list-disc list-inside">
                                    <li>75% of users possibly acess the app on mobile</li>
                                    <li>More people have taken quizzes than they have created</li>
                                    <li>One common action users take is searching for a quiz</li>
                                    <li>Most users discovered quizboot from other users</li>

                                </ul>
                            </p>

                        </div>
                    </div>


                    <p class="text-xl font-semibold text-left pt-8 pb-3">Ideate</p>
                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 pb-8 text-left break-words">
                        Bearing in mind insights from the research + understand phase, I moved right
                        into structuring the navigation and flow for each feature from the
                        main touch point (the home page). I identified the major
                        navigational elements and explored ways to represent them visually.
                    </p>
                    <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610912900/quizboot/Ideate_phase.svg"
                        class="mx-auto"
                        alt="quizboot-discover"
                    />

                </section>

                <section id="section-3" class="current">
                    <p class="text-2xl font-semibold text-left pb-4 pt-8">Visual Design</p>
                    <div class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">

                        <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7   pb-8 text-left break-words">
                            Based on the insight that over 75% of users access the platform on
                            a mobile device, it was necessary to take a mobile first approach.
                            A low mid-fidelity version of the first set of
                            screens was created.
                        </p>

                        <img
                            src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610833903/quizboot/wireframe.png"
                            class="mx-auto bg-secondary"
                            alt="quizboot-discover"
                        />
                        <p class="subpixel-antialiased pt-4 text-sm font-base leading-7 pb-4 text-center text-gray-300">
                            Home screen and create quiz screen
                        </p>
                        <img
                            src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610833901/quizboot/wireframes_II.png"
                            class="mx-auto bg-secondary pt-4"
                            alt="quizboot-discover"
                        />
                        <p class="subpixel-antialiased py-4 text-sm font-base leading-7 text-center text-gray-300">
                            Design focusing on question creation
                        </p>

                        <p class="subpixel-antialiased text-base md:text-l font-base leading-7 pb-8 text-left break-words">
                            The initial plan was to test the designs with users before moving
                            on to high-fidelity but because of time constraints and a limited
                            budget I couldn’t test with actual users but I got a few people
                            who were representative of the typical users to interact with the
                            mock-ups and got feedback mostly on the navigation which I
                            iterated on while moving on to the high-fidelity design.
                        </p>

                        <img
                            src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610834729/quizboot/Component_Library.png"
                            class="mx-auto bg-gray-50"
                            alt="quizboot-discover"
                        />
                        <p class="subpixel-antialiased text-sm font-base leading-7 py-4 text-center text-gray-300">
                            Component Library
                        </p>

                        <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 pb-8 text-left break-words">
                            One of the major touch points is the homepage which is a quiz
                            “social” timeline where users can see quizzes created by people
                            they follow. Since one of the most used feature is searching
                            for/joining a quiz, it was valuable to add a section where users
                            can easily join a quiz with the quiz id.
                        </p>

                        <img
                            src="https://res.cloudinary.com/dcj89ranh/image/upload/v1610835239/quizboot/High_fidelity.png"
                            class="mx-auto bg-secondary"
                            alt="quizboot-discover"
                        />
                        <p class="subpixel-antialiased text-sm font-base leading-7 py-4 text-center text-gray-300">
                            High-fidelity design of the homepage (Side bar showing connection
                            recommendations to users)
                        </p>

                        <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7   pb-8 text-left break-words">
                            Also, to push the social value of the platform a sidebar is
                            available on the desktop web version for a user to connect with
                            other users. The mobile web version of the homepage is consistent
                            with the desktop version with the sidebar being the only major
                            difference.
                        </p>

                        <img
                            src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610836003/quizboot/Quiz_page.png"
                            class="mx-auto bg-secondary"
                            alt="quizboot-discover"
                        />
                        <p class="subpixel-antialiased py-4 text-sm font-base leading-7 text-center text-gray-300">
                            Mobile and desktop web versions of the quiz page
                        </p>
                    </div>
                </section>

                <section id="section-4" class="current">
                    <p class="text-2xl font-semibold text-left pb-4 pt-8">Review and Handoff</p>
                    <div class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                        <p class="subpixel-antialiased text-base md:text-l font-base leading-7 pb-8 text-left break-words">
                            As mentioned earlier, there were time constraints and limited
                            resources at my disposal so I couldn't conduct a proper user
                            testing session. It was agreed that we would rely on feedback from
                            users after the launch of the redesigned platform to further
                            iterate and optimise features.
                        </p>

                        <img
                            src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610836103/quizboot/figma_handoff.png"
                            class="mx-auto md:w-2/3"
                            alt="quizboot-handoff"
                        />
                        <p class="subpixel-antialiased py-0 text-sm font-base leading-7 pb-4 text-center text-gray-300">
                            Figma's dev handoff feature
                        </p>

                        <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                            After completing most of the screens, I handed off to the
                            developers working on the platform. This was quite seamless as the
                            designs were done in Figma which has an inbuilt handoff feature
                            that allows designers to not just handoff but also collaborate
                            with developers while they build out designs into code.
                        </p>
                    </div>
                </section>

                <section id="section-5" class="current">
                    <p class="text-2xl font-semibold text-left pb-4 pt-8">Outcomes and Learnings</p>
                    <div class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 pb-8 text-left break-words">
                        <p class="subpixel-antialiased text-base md:text-l font-base leading-7 pb-2 text-left">
                            The redesign features a more consistent and easier navigation
                            structure which solves the initial problem users had with finding
                            and recalling some of the features on the platform. Also, the
                            redesign allows users to signup with an email address or a phone
                            number.
                        </p>

                        <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 pb-2 text-left">
                            It was valuable to allow users signup with a phone number because
                            of the required user verification (this was necessary to remove
                            withdrawal limits from a user's wallet balance). When users sign
                            up with their phone number, they get a sms verification code sent
                            which they can easily copy (since they would probably be with
                            their mobile device).
                        </p>

                        <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 pb-2 text-left">
                            In retrospect, one thing I would have done differently is to have
                            better defined features at the start of the project (there was a
                            quite some back and forth with stakeholders regarding this) and
                            also to have had a UX researcher on the team or to have conducted
                            more user testing sessions instead of relying on post launch to
                            get feedback from users.  Check out <Link to="https://quizboot.com/" target="_blank" class="font-medium underline hover:text-orange-600">quizboot.com</Link>

                        </p>
                    </div>
                </section>
            </div>
        </div>
        <Recommendations projectName="quizboot" />
        <Footer />
    </Layout>
)

export default Quizboot
